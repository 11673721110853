import * as React from "react"
import { graphql, Link } from "gatsby"
import { Layout } from "../../../components/layout"
import isEqual from "lodash.isequal"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { StoreContext } from "../../../context/store-context"
import { AddToCart } from "../../../components/add-to-cart"
import { NumericInput } from "../../../components/numeric-input"
import clsx from "clsx"
import { formatPrice } from "../../../utils/format-price"
import { Seo } from "../../../components/seo"


import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { CgChevronRight as ChevronIcon } from "react-icons/cg"
import {
  productBox,
  container,
  header,
  productImageWrapper,
  productImageList,
  productImageListItem,
  scrollForMore,
  noImagePreview,
  optionsWrapper,
  priceValue,
  selectVariant,
  labelFont,
  breadcrumb,
  tagList,
  addToCartStyle,
  metaSection,
  productDescription,
} from "./product-page.module.css"

export default function Product({ data: { product, suggestions } }) {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    images,
    collections,
    descriptionHtml,
    tags,
  } = product
  const { client } = React.useContext(StoreContext)



  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  console.warn(productVariant)


  // set swiper to state
  const [swiperState, setSwiperState] = React.useState(null)

  const initSwiper = (swiper) => {
    console.log(swiper)
    setSwiperState(swiper)
  }

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  // onMount let swiper
  console.log(collections.title)
  
  let html = {__html: descriptionHtml};
  
  const handleVariantImageChange = (variant, name) => {

    // add flag for no color variant
    if (name.toLowerCase() === "color") {
    let variantImage = variant.image?.gatsbyImageData.images.fallback?.src;

    console.log("variant is", variant)

    if (!variantImage) {
      return
    }
    variantImage = variantImage.split("?v")[0];
    let slides = swiperState.slides;
    let currentSwiperImg = slides[swiperState.activeIndex].querySelector("picture").querySelector("img").src

    console.log(variantImage)

    // if current image is not the same as the selected variant image slide to the slide with the selected variant image
    if (currentSwiperImg !== variantImage) {
      for (let i in slides) {
        let img = slides[i].querySelector("picture").querySelector("img").src.split("?v")[0];
        console.log(img, variantImage, img.split("?v")[0] === variantImage.split("?v")[0])
        if (img === variantImage) {
          swiperState.slideTo(i)
        }
      }       
    }
  }
  }

  const handleOptionChange = (index, event, name) => {
    const value = event.target.value
    


    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    console.log("event is", event)
    handleVariantImageChange(selectedVariant, name)

    setVariant({ ...selectedVariant })
  }

  // On variant change, console log variant


  const price =  `$` + ~~priceRangeV2.minVariantPrice.amount;


  const hasVariants = variants.length > 1
  const hasImages = images.length > 0
  const hasMultipleImages = true || images.length > 1


  console.log(tags)

  let ogTag;
  for (var i = 0; i < tags.length; i++) {

    if (tags[i].split("-").includes("og")) {
      ogTag = tags[i].split("-")[1];
    
    } 
  }
  
  console.log(ogTag)
  return (
    <Layout>
      <div className={container}>
        <div className="">
          <Swiper
            onSwiper={(swiper) => initSwiper(swiper)}
          >
          {hasImages && (
            <div className=" md:pt-0">
            {Image(images, title, hasMultipleImages)}
            </div>
          )}
          </Swiper>
     
     
     
          <div>
            <div className="font-bold flex flex-col justify-center text-center">
              
            <h1 className="">{title} </h1>
            {ogTag && 
            <span className="text-gray-400 line-through">${ogTag} </span>  
            
            }

            <h2 className={ogTag && "text-red-500"}>
            {price} 
            </h2>
            {collections[0] && (
              <h3 className="">{collections[0].title}</h3>
            )}
            </div>
            <div className="flex flex-col mx-auto pt-[20px] justify-center text-center">

            <fieldset className={clsx( "flex flex-col self-center ")}>
              {hasVariants &&
                options.map(({ id, name, values }, index) => (
                  <div className={selectVariant} key={id}>

                    <select
                      aria-label="Variants"
                      onChange={(event) => handleOptionChange(index, event, name)}
                       value={variant.selectedOptions[index].value}
                    >
                      {values.map((value) => (
                        <option value={value} key={`${name}-${value}`}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
            </fieldset>
            <div className={clsx(addToCartStyle, "pt-[10px] flex flex-col child:flex self-center")}>
              <NumericInput
                aria-label="Quantity"
                onIncrement={() => setQuantity((q) => Math.min(q + 1, 20))}
                onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                onChange={(event) => setQuantity(event.currentTarget.value)}
                value={quantity}
                min="1"
                max="20"
              />
              {/* if collection is RED LABEL */}
              <AddToCart
                variantId={productVariant.storefrontId}
                quantity={quantity}
                available={productVariant.availableForSale}
                color={collections[0] && collections[0].title || "black"}
              />
            </div>
            <div
            dangerouslySetInnerHTML={ 
              html
            } className={clsx(productDescription, "pt-20 flex flex-col gap-5 items-center")}></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ data: { product } }) => {
  const {
    title,
    description,
    images: [firstImage],
  } = product

  return (
    <>
      {firstImage ? (
        <Seo
          title={title}
          description={description}
          image={getSrc(firstImage.gatsbyImageData)}
        />
      ) : undefined}
    </>
  )
}

export const query = graphql`
  query($id: String!, $productType: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      descriptionHtml
      productType
      collections {
        title
        handle
        id
      }
      productTypeSlug: gatsbyPath(
        filePath: "/products/{ShopifyProduct.productType}"
      )
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        # altText
        id
        gatsbyImageData
      }
      variants {
        availableForSale
        storefrontId
        title
        image {
          gatsbyImageData
        }
        price
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
    }
    suggestions: allShopifyProduct(
      limit: 3
      filter: { productType: { eq: $productType }, id: { ne: $id } }
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`



function Image(images, title, hasMultipleImages) {
  return <div className={productImageWrapper}>
    <div
      role="group"
      aria-label="gallery"
      aria-describedby="instructions"
    >
      <ul className={productImageList}>
        {images.map((image, index) => (
          <SwiperSlide
            index={index}
            key={`product-image-${image.id}`}
            className={productImageListItem}
          >
            <GatsbyImage
              objectFit="contain"
              loading={index === 0 ? "eager" : "lazy"}
              className="min-h-full p-2 h-[70vh]"
              alt={image.altText
                ? image.altText
                : `Product Image of ${title} #${index + 1}`}
              image={image.gatsbyImageData} />
          </SwiperSlide>
        ))}
      </ul>
    </div>
    {hasMultipleImages && (
      <div className={scrollForMore} id="instructions">
        <span aria-hidden="true">←</span> scroll for more{" "}
        <span aria-hidden="true">→</span>
      </div>
    )}
  </div>
}

